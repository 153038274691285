@use "../global" as g;

/*------------------------------------------
	フッターグロナビ
------------------------------------------*/
.fnav {
	display: none;
	margin-top: 50px;	
	
	@include g.mq('md') {
		display: block;
	}
	
	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		gap: 30px;
		padding: 30px 0;
		margin: 0 auto;
		border-top: 1px solid #c4c4c4;
	}

	&__item {
		font-family: g.font('tit');
		font-size: 1.4rem;
		letter-spacing: 0.16em;

		&.is-instagram {
			width: 37px;
			height: 37px;
		}
	}
}