/*------------------------------------------
    アコーディオン
------------------------------------------*/
@use "../global" as g;

.aco02 {
    &__head {
        @include g.mq('md') {
            display: none;
        }

        a {
            position: relative;
            display: block;
            height: 33px;
            background-color: #FFF;
            font-weight: normal;
            
            @include g.mq('md') {
                grid-template-columns: 1fr 64px;
                height: 73px;
            }
        }
    }
    
    &__tit {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-size: 1.5rem;
        font-weight: normal;
        color: g.color('base');
        text-align: center;
        height: 100%;
        
        @include g.mq('md') {
            font-size: 1.5rem;
        }

        &.is-active{
            .is-open{
                display: none;
            }
            .is-close{
                display: block;
            }
        }

        .is-close{
            display: none;
        }
    }

    &__icon {
        transition: 0.2s;

        &::after{
            position: absolute;
            top: -6px;
            bottom: 0;
            right: 15px;
            margin: auto;
            content: "";
            display: block;
            @include g.arrow($type: 'bottom', $size: 1rem, $weight: 1px, $color: g.color('base'), $round: false, $skew: false);
        }
        
        &.is-active {
            &::after {
                 top: 8px;
                 transform: rotate(45deg);
            }
        }
    }
    
    &__body-wrapper {
        overflow: hidden;
        transition: 0.4s;
        
        &.v-enter-from, &.v-leave-to {
            opacity: 0;
        }

        @include g.mq('md') {
            height: auto !important;
            display: block !important;
        }
    }
    
    &__body {
        padding: 0px 0px 35px;
        margin-top: 10px;
        font-size: 1.4rem;
        color: #FFF;
        
        @include g.mq('md') {
            padding: 0px 0px 35px;
        }
    }
}