@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	sec
------------------------------------------*/
.sec {
    padding: 50px 0;
	
	@include g.mq('md') {
		padding: 100px 0;
	}

	&.is-bg-key {
		background-color: g.color('key');
	}

	&.is-bg-glay {
		background-color: g.color('base-sick');
	}

	&.is-bg-base {
		background-color: g.color('base');
	}

	&.is-bg-base-thin {
		background-color: g.color('base-thin');
	}
}

