@use "../global" as g;

/*------------------------------------------
	フォーム
------------------------------------------*/

/* フォーム */
.form {
    $this: ".form";
    margin-top: 25px;

    &__block:not(:first-child) {
        margin-top: 21px;
    }

    &__ttl {
        font-weight: bold;
    }

    &__table {
        margin-top: 8px;
    }

	&__row{
		margin-bottom: 20px;
	}

    &__th {
        display: flex;
        align-items: center;
        gap: 10px;
		font-size: 1.3rem;
		margin-bottom: 2px;
    }

    &__submit {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-top: 35px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 50px;

        &__block:not(:first-child) {
            margin-top: 28px;
        }

        &__table {
            margin-top: 5px;
        }

        &__submit {
            flex-direction: row-reverse;
            justify-content: center;
            margin-top: 50px;
        }
    }

    &.is-confirm{
        #{$this}__td{
            background: #FFF;
            padding: 15px 20px;
            font-size: 1.3rem;
        }
        #{$this}__confirm-txt{
            text-align: center;
            margin-bottom: 20px;
        }
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"] {
    -webkit-appearance: none;
    color: g.color('base');
}

select,
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="date"]::-webkit-date-and-time-value,
input[type="date"]::-webkit-date-and-time-edit,
input[type="date"]::-webkit-date-and-time-input,
label,
textarea{
    width: 100%;
	font-size: 1.3rem;
}

/* 必須 */
.required {
    padding: 2px 4px;
    font-weight: normal;
    line-height: 1.5;
    color: #fff;
    background-color: g.color('key');
	font-size: 0.8rem;
}

/* テキスト */
.text-input {
    display: flex;
    align-items: center;
    gap: 10px;

    &.is-mt{
        margin-top: 3px;
    }

    input {
        padding: 15px 20px;
        font-family: var(--base-font);
        font-size: 1.3rem;
        border: 1px solid #D2CDCD;
        border-radius: 0;

        &::placeholder {
            color: #9D9B9B;
        }
    }

    &__suffix{
        font-size: 1.3rem;
    }
}

/* ラジオボタン */
.radio-input {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    margin-top: 3px;
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #D2CDCD;
    background-color: #FFF;
    
    &.is-bg-none{
        padding: 0;
        border: none;
        background-color: transparent;
    }
    
    &__label{
        width: auto;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__suffix{
        font-size: 1.3rem;
    }

    input{
        background-color: g.color('base-thin');
    }
}


/* セレクトボックス */
.select-group__row {
    display: flex;
    align-items: center;
    gap: 10px;
}
.select-group__row:not(:first-child) {
    margin-top: 7px;
}
.select {
    position: relative;
    border: 1px solid #D2CDCD;
	background: #FFF;
    border-radius: 0;
	z-index: 1;
}
.select::before {
    content: '';
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    width: 18px;
    height: 8px;
    margin: auto;
    background: url('../img/frontpage/tri_bottom.svg') no-repeat border-box center/cover;
	z-index: -1;
}
.select select {
    width: 100%;
    padding: 15px 20px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--base-font);
    color: var(--base-color);
	background-color: transparent;
}

/* テキストエリア */
.textarea-input{
    textarea{
        padding: 15px 20px;
        min-height: 140px;
    }
}

/* 注意書き */
.notice.is-tenure {
    margin-top: 5px;
}
.notice.is-file {
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 2;
}

@media screen and (min-width: 768px) {
    .notice {
        margin-top: 10px;
    }
}

/* エラー表示 */
.errors {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}
.errors__item {
    padding: 5px 15px;
    font-weight: 500;
    color: red;
    border: 1px solid red;
    margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
    .errors__item {
        padding: 10px 20px;
        text-align: center;
    }
}

/* ボタン */
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
}
.btn:hover {
    opacity: 0.7;
}
.btn.is-submit {
    .is-form{
        color: #fff;
        background-color: g.color('key');
    }
}
.btn.is-back {
    span{
        color: g.color('base');
    }
}

@media screen and (min-width: 768px) {
}

/* バリデーション後inputの背景色 */
.invalid{
    background: rgb(255, 225, 225);
}

/* 完了画面 */
.header-completed{
    padding: 20px 0;
    
    @include g.mq('md') {
        padding: 40px 0;
    }
}
.completed{
    &__txt{
        margin-top: 50px;
        text-align: center;
    }

    &__btn{
        margin-top: 50px;
        a{
            color: g.color('base');
            border: 1px solid g.color('base');
        }
    }
}

