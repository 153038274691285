@use "../global" as g;

/*------------------------------------------
	ハンバーガーメニュー
------------------------------------------*/
.menubar {
	position: relative;
	z-index: map-get(g.$z-index, 'header');
	display: inline-block;
	width: 45px;
	height: 45px;
	padding: 3px;
	
	@include g.mq('md') {
		width: 50px;
		height: 50px;
	}

	span {
		$paddingY: 10px; //バーの間隔をここで調整
		position: absolute;
		left: 0;
		right: 0;
		display: inline-block;
		margin: auto;
		width: 64%;
		height: 2px;
		background-color: #FFF;
		transition: position .4s;

		&:nth-of-type(1) {
            top: $paddingY; 
        }

        &:nth-of-type(2) {
            top: 0;
			bottom: 0;
        }

        &:nth-of-type(3) {
            bottom: $paddingY; 
        }
	}

	.is-fixed &{
		span{
			background-color: g.color('key');
		}
	}
}

