@use "../global" as g;

/*------------------------------------------
	グロナビ
------------------------------------------*/
.gnav {
	z-index: map-get(g.$z-index, 'header');
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(g.color('key'), 0.95);
	visibility: hidden;
	height: 0;
	transition: 0.4s;
	overflow-y: scroll;

	&.is-active {
		visibility: visible;
		height: 100%;
	}

	&__inner{
		padding: 20px 0;
		
		@include g.mq('md') {
			padding: 0 40px 20px !important;
			display: flex;
			align-items: center;
			flex-direction: column;
			position: relative;
			flex-wrap: wrap;
		}
		
		@include g.mq('lg') {
			padding-top: 140px !important;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: row;
		}
	}

	&__flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		
		@include g.mq('md') {
			height: 460px;
			justify-content: center;
			text-align: center;
		}
	}

	&__logo{
		width: 110px;
		@include g.mq('md') {
			width: 144px;
		}
	}

	&__close {
		position: relative;
		@include g.close-icon(30px, 2px, #FFF);

		@include g.mq('md') {
			position: absolute;
			top: 30px;
			right: 35px;
		}
	}

	&__btn{
		margin-bottom: 15px;
		
		@include g.mq('md') {
			margin-bottom: 30px;
		}
		
		a{
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			height: 56px;
			padding: 10px 20px 10px 15%;
			margin: 0 auto;
			font-size: 1.7rem;
			font-weight: 600;
			color: g.color('key');
			background-color: #FFF;
			
			@include g.mq('md') {
				padding: 10px 20px 10px 30px;
				width: 327px;
			}

			.is-arrow{
				position: absolute;
				right: 10%;
				top: 50%;
				transform: translateY(-50%);
				
				@include g.mq('md') {
					right: 46px;
				}
			}
		}
	}

	&__box{
		@include g.mq('md') {
			width: 327px;
		}
	}

	&__list-wrap{
		position: relative;

		@include g.mq('md') {
			width: 580px;
		}
	}

	&__item {
		a {
			position: relative;
			display: inline-block;
			font-size: 2.6rem;
			letter-spacing: 0.09em;
			color: #FFF;
			padding: 10px 0;
			font-family: g.font('en');

			.is-miniTxt{
				font-size: 1.1rem;
				font-family: g.font('base');
				margin-left: 5px;
				letter-spacing: 0.2em;
				
				@include g.mq('md') {
					margin-left: 15px;
				}
			}
		}

		&.is-small{

			@include g.mq('md') {
				display: inline-block;
				margin-right: 5px;
				margin-top: 108px;
			}

			a{
				font-size: 1.1rem;
				font-family: g.font('base');

				@include g.mq('md') {
					position: relative;
					display: inline-block;
					border: 1px solid #FFF;
					width: 156px;
					padding: 10px;
					text-align: center;
				}

				.is-arrow{
					@include g.mq('md') {
						position: absolute;
						top: 50%;
						right: 26px;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	&__sns-list {
		position: absolute;
		bottom: 0;
		right: 0;
		
		@include g.mq('md') {
			bottom: 8px;
		}
	}
	
	&__sns-item {
		margin: 0 10px;

		a {
			display: block;
			width: 30px;
			height: 30px;
		}
	}
}