/*------------------------------------------
    パンくず
------------------------------------------*/
@use "../global" as g;

.breadcrumb {
    display: flex;

    &__item {
        font-size: 1.3rem;
        color: g.color('base');

        &:not(:last-of-type) {
            margin-right: 10px;
            
            a {
                padding-right: 10px;
            }

            &::after {
                content: '>';
            }
        }
    }
}