/*------------------------------------------
inner
------------------------------------------*/
@use "../global" as g;

.inner {
	width: g.$inner-width-sp;
	max-width: 100%;
	margin: 0 auto;
    
    @include g.mq('md') {
        max-width: g.$inner-width;
		width: 100%;
		margin: 0 auto;
        padding: 0 40px;
	}
	
	&.is-pc {
        @include g.mq('sm') {
            width: 100%;     
        }
	}

    &.is-sp {
        @include g.mq('md') {
            width: 100%;
        }
    }

    &.is-small {
        @include g.mq('md') {
            width: g.$inner-width-small;
        }
    }
}