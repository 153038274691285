// ブレイクポイント
$breakpoints: (
    'xs': 'screen and (max-width: 375px)',
    'sm': 'screen and (max-width: 767px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 1000px)',
    'lg-max': 'screen and (max-width: 999px)',
    'xl': 'screen and (min-width: 1140px)',
    'xl-max': 'screen and (max-width: 1139px)',
)!default;

// inner-width
$inner-width: 1080px;
$inner-width-small: 720px;
$inner-width-sp: 87%;

// フォント
// 出力例) font('base');
$font: (
    'base': ("Zen Kaku Gothic New", "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif),
    'en': ("Barlow", sans-serif),
);

// 色管理
// 出力例) color('base');
// 多次元mapの出力例) color('cat', 'event', 'performance');
$color: (
	'base': #686767,
    'base-sick': #3E3A39,
    'base-thin': #E1E1E1,
    'key': #D1437C,
    'weight': #00849C,
    'yoga': #B85072,
    'fitness': #6671A4,
    'before': #649CA6,
    'faq': #E48EB0,
    // 'bg': #3E3A39,
    // 'accent': #42210B,
    // 'border': #D6E7DF,
);

// 重ね順管理
$z-index: (
    'header': 1000,
    'gotop': 999,
);

//ヘッダーの高さ
$headerH: (
    'sm': 70px,
    'md': 90px
);