/*------------------------------------------
    パンくず
------------------------------------------*/
@use "../global" as g;

.arrow {
	position: relative;
	display: inline-block;
	vertical-align: middle;

    &::before,&::after{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: "";
        vertical-align: middle;
    }

    &::before{
        left: 0;
        width: 13px;
        height: 1px;
        background: g.color('key');
        
        @include g.mq('md') {
            width: 26px;
        }
    }
    &::after{
        left: 6px;
        width: 5px;
        height: 6px;
        border-top: 1px solid #D1437C;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);

        @include g.mq('md') {
            left: 15px;
            width: 10px;
            height: 9px;
        }
    }

    &.is-small{
        &::before{
            left: 0;
            width: 13px;
            height: 1px;
        }

        &::after{
            left: 6px;
            width: 5px;
            height: 6px;
        }

        @include g.mq('sm') {
            top: -1px;
            width: 16px;
            height: 2px;
            background-color: g.color('key');

            &::before{
                top: -1px;
                left: 5px;
                width: 9px;
                height: 9px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                border-bottom: 2px solid g.color('key');
                background: none;
            }

            &::after{
                top: 1px;
                left: 5px;
                width: 9px;
                height: 9px;
                border-top: 2px solid g.color('key');
            }
        }
    }

    &.is-white{
        @include g.mq('sm') {
            background-color: #FFF;
        }

        &::before{
            @include g.mq('md') {
                background: #FFF;
            }

            @include g.mq('sm') {
                border-color: #FFF;
            }
        }
        &::after{
            border-color: #FFF;
        }
    }

    &.is-glay{
        @include g.mq('sm') {
            background-color: g.color('base');
        }

        &::before{
            @include g.mq('md') {
                background: g.color('base');
            }

            @include g.mq('sm') {
                border-color: g.color('base');
            }
        }
        &::after{
            border-color: g.color('base');
        }
    }

    &.is-type02{
        @include g.mq('sm') {
            top: -1px;
            width: 11px;
            height: 1px;
            background: #FFF;

            &::before{
                left: 4px;
                width: 6px;
                height: 6px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                border-bottom: 1.1px solid #FFF;
                background: none;
            }

            &::after{
                left: 4px;
                width: 6px;
                height: 6px;
                border-top: 1.1px solid #FFF;
            }
        }
    }
}

