/*------------------------------------------
    table
------------------------------------------*/
@use "../global" as g;

.table {
    width: 100%;
    border: 1px solid g.color('border');
    border-collapse: collapse;

    &__th, &__td {
        padding: 10px 20px;
        
        @include g.mq('sm') {
            display: block;
        }
        
        @include g.mq('md') {
            border: 1px solid g.color('border');
        }
    }
    
    &__th {
        color: #fff;
        background-color: g.color('accent');
        
        @include g.mq('md') {
            width: 30%;
        }
    }
    
    &__td {
        @include g.mq('sm') {
            border-top: 1px solid g.color('border');
        }

        @include g.mq('md') {
            width: 70%;
        }
    }
}