@use "../global" as g;

/*------------------------------------------
	header
------------------------------------------*/
.header {
	$this: '.header';

	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	min-width: 100%;
	z-index: map-get(g.$z-index, 'header');
	
	&__flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: map-get(g.$headerH, 'sm');
		gap: 10px;
		
		@include g.mq('md') {
			height: map-get(g.$headerH, 'md');
			gap: 18px;
		}
	}

	&__logo {
		flex-grow: 1;

		a{
			display: none;

			width: 110px;
	
			@include g.mq('md') {
				width: 144px;
			}

			img{
				width: 100%;
			}
		}
	}

	&__cta{
		a{
			position: relative;
			display: flex;
			align-items: center;
			width: 115px;
			height: 34px;
			color: g.color('key');
			padding: 10px 12px 10px 12px;
			font-size: 1.2rem;
			font-weight: 600;
			background-color: #FFF;
			
			@include g.mq('md') {
				width: 192px;
				height: 41px;
				padding: 10px 20px 10px 30px;
				font-size: 1.46rem;
			}

			.is-sp-arrow{

				img{
					position: absolute;
					top: 2px;
					right: 8px;
					bottom: 0;
					margin: auto;
					width: 12px;
					height: auto;
					display: none;

					+ img{
						display: block;
					}
				}
			}

			.is-arrow{
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				
				@include g.mq('md') {
					right: 46px;
				}

				.arrow{
					background-color: g.color('key');

					&::before,&::after{
						border-color: g.color('key');
					}
				}
			}
		}
	}

	&.is-fixed{
		position: fixed;
		background-color: #fff;
		box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
		opacity: 0;

		#{$this}__logo{
			a{
				display: block;
			}
		}

		#{$this}__cta{
			a{
				background-color: g.color('key');
				color: #fff;

				.is-sp-arrow{
					img{
						display: block;

						+ img{
							display: none;
						}
					}
				}

				.is-arrow{
					.arrow{
						background-color: #FFF;
	
						&::before,&::after{
							border-color: #FFF;
						}

						@include g.mq('md') {
							background-color: g.color('key');

							&::before{
								background: #FFF;
							}
						}
					}
				}
			}
		}
	}

	&.is-transition{
		transition: all 0.3s ease;
	}

	&.is-fade{
		opacity: 1;
	}
}

