@use "../global" as g;

/*------------------------------------------
	footer
------------------------------------------*/
.footer{
	padding: 78px 0 46px;
	background-color: g.color('key');

    &__logo {
		margin-bottom: 35px;
		text-align: center;
		
		@include g.mq('md') {
			margin-bottom: 75px;
		}
	}

	&__snsList{
		margin-bottom: 50px;
	}

	&__snsItem{
		text-align: center;
	}

	&__copyright {
		font-family: g.font('en');
		font-size: 1rem;
		letter-spacing: 0.1em;
		text-align: center;
		color: #FFF;
		
		@include g.mq('md') {
			font-size: 1.0rem;
			margin-top: 0;
		}
	}
}