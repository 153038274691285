@use "../global" as g;

/*------------------------------------------
	tit
------------------------------------------*/
.tit {
	font-family: g.font('en');
	font-size: 3.2rem;
	font-weight: normal;
	text-align: center;
	line-height: 1.4;
	letter-spacing: 0.1em;
	color: #FFF;

	@include g.mq('md') {
		font-size: 3.6rem;
	}

	span {
		display: block;
		margin-top: 0.5em;
		font-family: g.font('base');
		font-size: 1.4rem;
		font-weight: normal;
		letter-spacing: 0.16em;
		color: #FFF;

		@include g.mq('md') {
			font-size: 1.8rem;
		}
	}

	&.is-pink {
		color: g.color('key');
		
		span{
			color: g.color('key');
		}
	}

	&.is-left {
		text-align: left;
	}
}


