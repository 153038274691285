/*******************************************
    トップページ
*******************************************/
@use "../global" as g;

#frontpage {

/*------------------------------------------
    mv
------------------------------------------*/
.mv {
    &__pic{
        img{
            width: 100%;
        }
    }
}

/*------------------------------------------
    catchcopy
------------------------------------------*/
.catchcopy{

    &__pause{
        margin-bottom: 70px;
    }

    &__txt{
        font-size: 1.5rem;
        line-height: 1.867;
        margin-bottom: 50px;
        
        @include g.mq('md') {
            text-align: center;
        }
    }

    &__flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 22px;
        
        @include g.mq('md') {
            flex-direction: row;
            gap: 40px;
            margin-bottom: 55px;
        }
    }

    &__subtit{
        font-size: 2.1rem;
        font-weight: bold;
        color: g.color('key');
        
        @include g.mq('md') {
            flex: 0 0 auto;
            font-size: 2.6rem;
        }
    }
}

/*------------------------------------------
    campaign
------------------------------------------*/
.campaign{
    $this: ".campaign";

    &__tit{
        margin-bottom: 30px;
        
        @include g.mq('md') {
            margin-bottom: 60px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    &__item{
        position: relative;

        > a{
            @include g.mq('sm') {
                pointer-events: none;
            }
        }

        &:nth-of-type(3){
            #{$this}__btn{
                bottom: 30px;
            }
        }
    }

    &__btn{
        position: absolute;
        bottom: 25px;
        right: 0;
        left: 0;
        margin: auto;

        a{
            min-width: 279px;
            height: 33px;
            line-height: 33px;
        }
    }
}

/*------------------------------------------
    voice
------------------------------------------*/
.voice{
    overflow: hidden;
    padding: 0 0 50px;
    
    @include g.mq('md') {
        padding: 0 0 100px;
    }

    &__wrap{
        position: relative;
        color: #FFF;
        
        @include g.mq('md') {
            padding-left: g.clamp_size(300,480) ;
            padding-top: 125px;
        }
    }

    &__tit-box{
        @include g.mq('sm') {
            position: relative;
            margin-bottom: 30px;
        }
    }

    &__tit{
        margin-bottom: 45px;

        @include g.mq('sm') {
            position: absolute;
            top: 130px;
            left: 0;
            right: 0;
            margin: auto;
        }
        
        @include g.mq('md') {
            display: flex;
            align-items: center;
            gap: 30px;
        }

        span{
            margin-top: 0;
        }
    }

    &__pic{
        width: 100vw;
        margin: 0 calc(50% - 50vw);

        @include g.mq('md') {
            position: absolute;
            top: 0;
            left: -140px;
            width: g.clamp_size(400,486) ;
            height: 100%;
            object-fit: cover;
            margin: 0;
        }

        img{
            width: 100%;
        }
    }

    &__label{
        position: absolute;
        top: g.get_vw(226);
        right: 0;
        width: g.get_vw(109);
        
        @include g.mq('md') {
            top: 115px;
            right: 0;
            width: 138px;
        }
    }

    &__txt{
        text-align: center;
        margin-bottom: 50px;
        
        @include g.mq('md') {
            text-align: justify;
            margin-bottom: 60px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 60px;
        
        @include g.mq('md') {
            gap: 20px;
        }
    }

    &__btn{
        margin: 0 auto;
    }
}

/*------------------------------------------
    habit
------------------------------------------*/
.habit{
    padding: 0 0 50px;
    overflow: hidden;
    
    @include g.mq('md') {
        padding: 0 0 100px;
    }

    &__wrap{
        position: relative;
        color: #FFF;
        margin-bottom: 60px;
        
        @include g.mq('md') {
            padding-top: 140px;
            margin-bottom: 80px;
        }
    }
    
    &__tit{
        margin-bottom: 50px;
        font-size: 2.8rem;
        letter-spacing: 0.2em;
    }

    &__txt{
        position: relative;
        margin-bottom: 50px;
        font-size: 1.5rem;
        
        @include g.mq('md') {
            width: 530px;
        }
    }

    &__pic{
        width: 100vw;
        margin: 0 calc(50% - 50vw);

        @include g.mq('md') {
            position: absolute;
            top: 0;
            right: -140px;
            width: g.clamp_size(400,486) ;
            margin: 0;
        }

        img{
            width: 100%;
        }
    }

    &__btn{
        @include g.mq('sm') {
            margin: 0 auto
        }
    }
}

/*------------------------------------------
    program
------------------------------------------*/
.program{
    &__tit{
        margin-bottom: 25px;
        
        @include g.mq('md') {
            margin-bottom: 50px;
        }
    }

    &__list{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 18px;
        margin-bottom: 50px;
        
        @include g.mq('sm') {
            gap: 20px;
            padding-top: 30px;
            border-top: 1px solid #FFF;
        }
        
        @include g.mq('md') {
            flex-direction: row;
            gap: 45px;
        }
    }

    &__item{
        color: #FFF;

        a{
            display: flex;
            align-items: center;
            gap: 15px;

            &::after{
                position: relative;
                top: -2px;
                content: "";
                display: block;
                @include g.arrow($type: 'bottom', $size: 1rem, $weight: 1px, $color: #FFF, $round: false, $skew: false);
            }
        }
    }

    &__list-detail{
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        @include g.mq('md') {
            gap: 8px;
        }
    }

    &__item-detail{
        @include g.mq('md') {
            display: flex;
        }
    }

    &__pic{
        img{
            @include g.mq('sm') {
                width: 100%;
            }
        }
    }

    &__box{
        flex-basis: 480px;
        background-color: #FFF;
        padding: 0  24px 30px;
        
        @include g.mq('md') {
            padding: 40px 55px;
        }
    }

    &__label{
        margin-top: -15px;
        text-align: center;
        
        @include g.mq('md') {
            margin-top: 0;
            text-align: center;
        }

        &.is-normal{
            margin-top: 0;
            padding-top: 20px;
            padding-bottom: 10px;
            
            @include g.mq('md') {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &__sub-tit{
        color: g.color('weight');
        font-size: 2.6rem;
        font-weight: normal;
        font-family: g.font('en');
        line-height: 1.4;
        text-align: center;
        margin-bottom: 25px;
        
        @include g.mq('md') {
            font-size: 3.6rem;
        }

        &.is-yoga{
            color: g.color('yoga');
        }

        .is-small{
            display: block;
            font-size: 1.3rem;
            font-family: g.font('base');
            font-weight: bold;
            
            @include g.mq('md') {
                font-size: 1.8rem;
            }

            .is-color-nomal{
                margin-left: 10px;
                font-size: 1.1rem;
                color: g.color('base');
                font-weight: normal;
                
                @include g.mq('md') {
                    font-size: 1.4rem;
                }
            }
        }
    }

    &__txt{
        border-top: 1px solid g.color('base');
        padding-top: 25px;
        text-align: center;
        margin-bottom: 15px;
        font-size: 1.1rem;
        
        @include g.mq('md') {
            font-size: 1.4rem;
        }
    }

    &__list-unit{
        &.is-type01{
            margin-left: 20px;
            
            @include g.mq('md') {
                margin-left: 50px;
            }
        }
        &.is-type02{
            margin-left: 15px;
            
            @include g.mq('md') {
                margin-left: 40px;
            }
        }
    }

    &__lead{
        margin-bottom: 5px;
    }

    &__note{
        font-size: 1.1rem;
        letter-spacing: 0.01em;
        margin-bottom: 15px;

        a{
            color: g.color('key');
            text-decoration: underline;
        }
    }
    
    &__shop{
        border: 1px solid g.color('fitness');
        margin-bottom: 5px;
    }

    &__shop-tit{
        padding: 10px 20px;
        color: g.color('fitness');
        font-size: 1.1rem;
        border-bottom: 1px solid g.color('fitness');
    }

    &__shop-item{
        padding: 10px 20px;
        color: g.color('fitness');
        font-size: 1.1rem;
    }

    &__list-machine{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: 30px;
    }

    &__item-machine{
        width: calc(50% - 2.5px);
        padding: 2px 10px;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: #FFF;
        background-color: g.color('fitness');
    }
}

/*------------------------------------------
    coupon
------------------------------------------*/
.coupon{
    position: relative;
    padding: 0;

    &__pic{
        img{
            width: 100%;
        }
    }

    .inner{
        @include g.mq('md') {
            position: relative;
        }
    }

    &__icon{
        position: absolute;
        top: 0;
        right: 0;
        
        @include g.mq('md') {
            top: -30px;
        }
    }
}

/*------------------------------------------
    plan
------------------------------------------*/
.plan{
    padding: 30px 0 50px;
    
    @include g.mq('md') {
        padding: 60px 0 100px;
    }

    &__tit{
        margin-bottom: 45px;
        font-size: 1.6rem;
        font-weight: normal;
        
        @include g.mq('md') {
            font-size: 2.4rem;
            text-align: center;
        }
    }

    &__pic{
        text-align: center;
    }
}

/*------------------------------------------
    before-after
------------------------------------------*/
.before-after{
    $this: ".before-after";

    &__tit{
        margin-bottom: 40px;
    }

    &__pic{
        margin-bottom: 40px;

        @include g.mq('sm') {            
            width: 100vw;
            margin: 0 calc(50% - 50vw) 40px;
        }

        img{
            width: 100%;
        }
    }

    &__list{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 45px;
        color: #FFF;
        
        @include g.mq('md') {
            flex-direction: row;
            gap: 20px;
        }
    }

    &__item{
        @include g.mq('md') {
            max-width: 453px;
        }
    }

    &__box{
        display: flex; 
        align-items: center;
        gap: 15px;
        margin-bottom: 15px;
    }

    &__attribute{
        display: flex;
        border: 1px solid #FFF;
        padding: 2px 10px;
        font-size: 1.2rem;
        font-weight: bold;

        & > span:nth-child(2)::before {
            content: "";
            display: inline-block;
            width: 1px; // 縦棒の幅
            height: 1.3em; // 縦棒の高さ
            background-color: currentColor; // テキストの色に合わせる
            margin-right: 10px;
            margin-left: 10px;
            vertical-align: sub;
        }
    }

    &__period{
        font-size: 1.2rem;
    }

    &__comment{
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: normal;
        
        @include g.mq('md') {
            font-size: 2.0rem;
        }
    }

    &__dt{
        margin-bottom: 20px;
        font-size: 1.3rem;
        color: g.color('base');
        border-top: 1px solid #FFF;
        
        span{
            display: inline-block;
            padding: 5px 30px 5px 10px;
            background-color: #FFF;
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
        }
    }

    &__dd{
        font-size: 1.4rem;
        padding-bottom: 25px;

        &:last-of-type{
            border-bottom: 1px solid #FFF;
        }

        &:nth-of-type(1){
            min-height: 84px;
        }

        &:nth-of-type(2){
            min-height: 202px;
        }

        &:nth-of-type(3){
            min-height: 173px;
        }
    }

    &__list-txt{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &.is-check{
            display: block;

            #{$this}__item-txt{
                display: inline-block;
                position: relative;
                padding-left: 18px;
                margin-right: 20px;
                margin-bottom: 5px;

                &::before{
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 18px;
                    height: 21px;
                    background-image: url('/assets/img/frontpage/icon_check.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &__item-txt{
        position: relative;
        padding-left: 20px;
        letter-spacing: 0.4px;

        &::before{
            content: "□";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
        }

        span{
            border-bottom: 1px solid g.color('before');
        }
    }

    &__txt{
        span{
            border-bottom: 1px solid g.color('before');
        }
    }
}

/*------------------------------------------
    trainer
------------------------------------------*/
.trainer{
    padding: 0 0 50px;
    
    @include g.mq('md') {
        padding: 0 0 100px;
    }

    &__mv{
        position: relative;

        .inner{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            margin: auto;
        }
    }

    &__pic{
        img{
            width: 100%;
        }
    }

    &__tit{
        position: absolute;
        left: 0;
        bottom: 54px;
        text-align: left;
    }

    &__main{
        padding-top: 86px;
        color: #FFF;
    }

    &__lead{
        font-size: 2.0rem;
        font-weight: normal;
        margin-bottom: 50px;
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__txt{
        font-size: 1.5rem;
        margin-bottom: 90px;
    }
    
    &__list{
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        
        @include g.mq('md') {
            flex-direction: row;
            gap: 100px;
        }
    }

    &__item{
        @include g.mq('md') {
            width: 352px;
        }
    }

    &__item-pic{
        text-align: right;
        
        @include g.mq('sm') {
            width: 100vw;
            margin: 0 calc(50% - 50vw);
        }

        img{
             @include g.mq('sm') {
                width: g.get_vw(257);
             }
        }
    }

    &__item-box{
        margin-top: -130px;
    }

    &__item-tit{
        margin-bottom: 5px;
        font-size: 4.0rem;
        font-weight: normal;
        color: g.color('key');
        font-family: g.font('en');
        line-height: 1.0;
    }

    &__item-name{
        margin-bottom: 30px;
    }

    &__item-dt{
        margin-bottom: 20px;

        span{
            display: inline-block;
            padding: 5px 10px;
            font-size: 1.2rem;
            border: 1px solid #FFF;
        }
    }

    &__item-dd{
        font-size: 1.2rem;
    }
}

/*------------------------------------------
    facility
------------------------------------------*/
.facility{
    $this: ".facility";
    overflow: hidden;

    &__tit{
        margin-bottom: 40px;
    }
    &__lead{
        margin-bottom: 40px;
        font-size: 1.5rem;
        color: #FFF;
        
        @include g.mq('md') {
            text-align: center;
        }
    }

    &__slide{
        margin-bottom: 30px;
        overflow: hidden;
        margin-right: calc((100vw - 100%) * -1);

        &.is-02{
            #{$this}__item{
                width: 244px;
            }
        }
    }

    .splide__track{
        overflow: visible;
    }

    &__item-pic{
        position: relative;

        figcaption{
            position: absolute;
            left: 10px;
            bottom: 5px;
            font-size: 2.0rem;
            color: #FFF;
            font-family: g.font('en');
        }
    }

    &__box{
        margin-top: 20px;
        color: #FFF;
    }

    &__box-tit{
        margin-bottom: 5px;
        font-size: 1.5rem;
        font-weight: normal;
        text-align: left;
    }

    &__box-txt{
        font-size: 1.4rem;
        text-align: left;
    }

    .splide{
        padding: 0;
    }
}

/*------------------------------------------
    price
------------------------------------------*/
.price{
    $this: ".price";

    &__tit{
        margin-bottom: 50px;
    }

    &__item{
        margin-bottom: 35px;

        &.is-01{
            position: relative;

            #{$this}__item-comment{
                position: absolute;
                top: g.get_vw(125);
                right: g.get_vw(-10);
                width: g.get_vw(106);
            }
        }

        &.is-02{
            margin-bottom: 25px;
            
            @include g.mq('md') {
                margin-bottom: 50px;
            }

            #{$this}__item-pic{
                position: relative;
            }

            #{$this}__item-comment{
                position: absolute;
                top: 75px;
                right: -40px;

                @include g.mq('md') {
                    top: g.clamp_size(54, 75);
                    width: g.clamp_size(204, 304, 768, 1000);
                }
            }
        }

        &.is-03{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 25px;
            border-top: 2px solid g.color('key');
            padding-top: 25px;
            
            @include g.mq('md') {
                flex-direction: row;
                gap: 40px;
                margin-bottom: 50px;
                border-top: none;
                padding-top: 0;
            }
        }
    }

    &__item-note{
        margin-bottom: 2px;
        text-align: right;
        font-size: 1.2rem;
    }

    &__note{
        margin-top: 10px;
        font-size: 1.1rem;
        
        @include g.mq('md') {
            font-size: 1.2rem;
        }
    }
}

/*------------------------------------------
    flow
------------------------------------------*/
.flow{
    overflow: hidden;

    &__tit{
        margin-bottom: 25px;
        
        @include g.mq('md') {
            margin-bottom: 50px;
        }
    }

    &__slide {
        @include g.mq('sm') {
            overflow: hidden;
            margin-right: calc((100vw - 100%) * -1);
        }
    }

    &__item{
        @include g.mq('md') {
            width: calc(33.33% - 0.7rem);
        }

        img{
            @include g.mq('md') {
                width: 100%;
            }
        }
    }

    .splide__track{
        overflow: visible;
    }

    .splide{
        padding: 0;
    }
}

/*------------------------------------------
    faq
------------------------------------------*/
.faq{
    &__tit{
        margin-bottom: 40px;
        
        @include g.mq('md') {
            margin-bottom: 50px;
        }
    }

    &__item{
        border-top: 1px solid #FFF;

        &:last-of-type{
            border-bottom: 1px solid #FFF;
        }
    }
}

/*------------------------------------------
    access
------------------------------------------*/
.access{
    overflow: hidden;

    &__tit{
        margin-bottom: 50px;
        
        @include g.mq('md') {
            margin-bottom: 70px;
        }
    }

    &__flex{

        @include g.mq('md') {
            display: flex;
            justify-content: space-between;
        }
    }

    &__unit{
        margin-bottom: 45px;
        color: g.color('key');
        
        @include g.mq('md') {
            margin-bottom: 0;
        }
    }

    &__unit-tit{
        margin-bottom: 10px;
        font-size: 2.6rem;
        font-weight: bold;

        span{
            font-size: 1.5rem;
            font-weight: normal;
        }
    }

    &__unit-txt{
        margin-bottom: 30px;
        
        @include g.mq('md') {
            margin-bottom: 15px;
        }
    }

    &__unit-miniTit{
        margin-bottom: 10px;
        
        @include g.mq('md') {
            margin-bottom: 5px;
        }

        
        span{
            display: inline-block;
            padding: 1px 10px;
            border: 1px solid g.color('key');
            font-weight: bold;
            font-size: 1.3rem;
        }
    }

    &__pic-box{
        iframe{
            @include g.mq('sm') {
                width: 100vw;
                margin: 0 calc(50% - 50vw);
            }

            @include g.mq('md') {
                width: g.clamp_size(440, 675);
            }
        }
    }
}

/*------------------------------------------
    contact
------------------------------------------*/
.contact{
    &__txt{
        margin-top: 40px;
        font-size: 1.1rem;
        
        @include g.mq('md') {
            margin-top: 40px;
        }

        a{
            color: g.color('key');
            border-bottom: 1px solid g.color('key');
        }
    }
}
}
