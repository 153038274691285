@charset "utf-8";

/*******************************************
	/css/style.css
*******************************************/
@use "base";
@use "module";
@use "page";

//----------- フォント -----------//

// Barlow, Zen Kaku Gothic New
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;700&family=Zen+Kaku+Gothic+New:wght@500;700&display=swap');