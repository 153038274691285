@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	ボタン
------------------------------------------*/
.btn {
	text-align: center;

	a, .is-form {
		position: relative;
		$height: 56px;
		display: inline-block;
		min-width: 327px;
		height: $height;
		padding: 0 40px;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: $height;
		letter-spacing: 0.1em;
		color: g.color('key');
		background-color: #FFF;
		
		@include g.mq('md') {
			height: $height;
			line-height: $height;
			font-size: 1.7rem;
			background-color: #FFF;
		}
	}

	&.is-glay{
		a{
			color: g.color('base');
		}
	}

	&.is-w {
		a {
			color: g.color('base-thin');
			background-color: #fff;
		}
	}

	&.is-b {
		a {
			color: #FFF;
			background-color: g.color('base-sick');
		}
	}

	&.is-s {
		a {
			$height: 41px;
			min-width: 179px;
			height: $height;
			font-size: 1.2rem;
			line-height: $height;
			border-radius: math.div($height, 2);
		}
	}
	
	.is-arrow{
		position: absolute;
		right: 35px;
		top: 50%;
		transform: translateY(-50%);
		
		@include g.mq('md') {
			right: 46px;
		}
	}
}